import React from "react"
import styled from "styled-components"

import { Section, Box } from "../../components/Core"

const SectionBG = styled(Section)`
  position: relative;
  background: #000 ${({ bg }) => `url(${bg}) no-repeat center`};
  background-size: cover;
  background-attachment: fixed;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
`

const ContentBox = styled(Box)`
  position: relative;
  z-index: 10;
`

const Hero = ({ bgImg, topPad = "9rem", bottomPad = "5.5rem", children }) => {
  return (
    <>
      <SectionBG bg={bgImg}>
        <Box pt={topPad} pb={bottomPad} className="content-wrapper">
          <ContentBox>{children}</ContentBox>
        </Box>
      </SectionBG>
    </>
  )
}
export default Hero
